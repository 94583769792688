window.onload = function() {
    var nameInput = document.getElementById("registration-form-fname");
    var nameLastInput = document.getElementById("registration-form-lname");
    var cpfInput = document.getElementById("registration-form-cpf");
    var celularInput = document.getElementById("registration-form-phone");
    var emailInput = document.getElementById("registration-form-email");
    var emailConfirmInput = document.getElementById("registration-form-email-confirm");
    var passwordInput = document.getElementById("registration-form-password");
    var passwordConfirmInput = document.getElementById("registration-form-password-confirm");
    var checkButton = document.getElementById("add-to-email-list");
    var registerButton = document.getElementById("registerButton");
    var emailCorrect = document.querySelector(".emailCorrectImg");
    var emailConfirmCorrect = document.querySelector(".emailConfirmCorrectImg");
    var seeCreatePasswordImg = document.querySelector("#togglePasswordRegister");
    var seePasswordConfirmImg = document.querySelector("#togglePasswordRegisterConfirm");
    var alertaBtnCheck = document.querySelector("#alertaBtnCheck");
    emailCorrect.style.display = "none";
    emailConfirmCorrect.style.display = "none";
    alertaBtnCheck.style.display = "none";

    seeCreatePasswordImg.style.userDrag = 'none';
    seeCreatePasswordImg.style.webkitUserDrag = 'none';
    seeCreatePasswordImg.style.MozUserDrag = 'none';

    seePasswordConfirmImg.style.userDrag = 'none';
    seePasswordConfirmImg.style.webkitUserDrag = 'none';
    seePasswordConfirmImg.style.MozUserDrag = 'none';

        nameInput.addEventListener("blur", function(){
            var errorName = document.getElementById("form-fname-error")
            if(nameInput.value.length < 3 && nameInput.value.length > 0){
                errorName.style.display = "block";
                let aviso = "Por favor, insira um nome válido";
                errorName.textContent = aviso;
                registerButton.disabled = true;
            }else{
                errorName.style.display = "none";
                disabledButton()
            }
        })

        nameLastInput.addEventListener("blur", function(){
            var errorLastName = document.getElementById("form-lname-error");
            if(nameLastInput.value.length < 3 && nameLastInput.value.length > 0){
                errorLastName.style.display = "block"
                let aviso = "Por favor, insira um sobrenome válido";
                errorLastName.textContent = aviso;
                registerButton.disabled = true;
            } else{
                errorLastName.style.display = "none";
                disabledButton()
            }
        })

        cpfInput.addEventListener('input', function(e) {
            var value = e.target.value;
            value = value.replace(/\D/g, '');
          
            var formattedCPF = '';
            if (value.length <= 11) {
              formattedCPF += value.substring(0, 3);
              if (value.length > 3) {
                formattedCPF += '.' + value.substring(3, 6);
              }
              if (value.length > 6) {
                formattedCPF += '.' + value.substring(6, 9);
              }
              if (value.length > 9) {
                formattedCPF += '-' + value.substring(9);
              }
            }
          
            cpfInput.value = formattedCPF;
          });

          cpfInput.addEventListener("blur", function(){
            var errorCpf = document.getElementById("form-cpf-error");
            var cpf = cpfInput.value;
            var regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
            
            if (cpf.trim() !== '') {
                if (!regex.test(cpf)) {
                    errorCpf.style.display = "block";
                    errorCpf.textContent = "Por favor, digite um CPF válido e não cadastrado. Exemplo: XXX.XXX.XXX-XX";
                    registerButton.disabled = true;
                } else {
                    errorCpf.textContent = "";
                    registerButton.disabled = false;
                }
            }
        });

        celularInput.addEventListener('input', function(e) {
            var value = e.target.value;
            value = value.replace(/\D/g, '');
    
            var formattedValue = '';
            if (value.length > 0) {
                if (value.length <= 10) {
                    formattedValue = value;
                } else {
                    formattedValue += '(' + value.substring(0, 2) + ')';
    
                    if (value.length > 2) {
                        formattedValue += ' ' + value.substring(2, 7);
    
                        if (value.length > 7) {
                            formattedValue += '-' + value.substring(7);
                        }
                    }
                }
            }
    
            celularInput.value = formattedValue;
        });
    
        celularInput.addEventListener("blur", function() {
            var errorNumber = document.getElementById("form-phone-error");
            var celular = celularInput.value;
            var regex = /^\([1-9]{2}\) 9?[1-9]{1}[0-9]{3}\-[0-9]{4}$/;
            if (!regex.test(celular) && celular.length > 0) {
                errorNumber.style.display = "block"
                var aviso = "Por favor, insira um número válido. Exemplo: (XX) XXXXX-XXXX";
                errorNumber.textContent = aviso;
                registerButton.disabled = true;
            } else {
                errorNumber.style.display = "none";
                disabledButton()
            }
        });

        emailInput.addEventListener("blur", function emailValidation(){
            var errorEmail = document.querySelector(".form-email-error");
            var email = emailInput.value;
            var regex = /^[\w\.-]+@[\w\.-]+\.\w+$/;
            if(!regex.test(email) && email.length > 0){
                errorEmail.style.display = "block";
                errorEmail.textContent = "Por favor, insira um endereço de e-mail válido. Exemplo: gopharma@interplayers.com.br";
                registerButton.disabled = true;
                emailCorrect.style.display = "none";
            }else if(regex.test(email)){
                emailCorrect.style.display = "block";
                emailCorrect.style.userDrag = 'none';
                emailCorrect.style.webkitUserDrag = 'none';
                emailCorrect.style.MozUserDrag = 'none';
                emailsEqual()
                errorEmail.style.display = "none";
                disabledButton()
            }
            else {
                errorEmail.style.display = "none";
                disabledButton()
            }
            emailsEqual()

        })

        emailConfirmInput.addEventListener("blur", function emailValidationConfirm(){
            var errorConfirmEmail = document.querySelector(".form-email-confirm-error");
            var emailConfirm = emailConfirmInput.value;
            var regex = /^[\w\.-]+@[\w\.-]+\.\w+$/;
            if(!regex.test(emailConfirm) && emailConfirm.length > 0){
                errorConfirmEmail.style.display = "block";
                errorConfirmEmail.textContent = "Por favor, insira um endereço de e-mail válido. Exemplo: gopharma@interplayers.com.br";
                emailConfirmCorrect.style.display = "none";
                registerButton.disabled = true;
            }else if(regex.test(emailConfirm)){
                errorConfirmEmail.style.display = "none";
                emailConfirmCorrect.style.display = "block";
                emailConfirmCorrect.style.userDrag = 'none';
                emailConfirmCorrect.style.webkitUserDrag = 'none';
                emailConfirmCorrect.style.MozUserDrag = 'none';
                disabledButton();
            }
            else {
                errorConfirmEmail.style.display = "none";
                disabledButton();
            }
            emailsEqual()
        })

        passwordInput.addEventListener("blur", function(){
            var errorPassword = document.querySelector(".form-password-error");
            if(passwordInput.value.length <= 1 && passwordInput.value.length > 0){
                errorPassword.style.display = "block";
                let aviso = "Por favor, digite sua senha";
                errorPassword.textContent = aviso;
                registerButton.disabled = true;
            } else{
                errorPassword.style.display = "none";
                disabledButton()
            }
            passwordsEqual()
        })

        passwordConfirmInput.addEventListener("blur", function(){
            var errorPasswordConfirm = document.getElementById("form-password-confirm-error");
            if(passwordConfirmInput.value.length < 1){
                errorPasswordConfirm.style.display = "block";
                let aviso = "Por favor, confirme a sua senha";
                errorPasswordConfirm.textContent = aviso;
                registerButton.disabled = true;
            } else{
                errorPasswordConfirm.style.display = "none";
                disabledButton()
            }
            passwordsEqual()
        })

        registerButton.addEventListener("click", function emailRegistered(){
            var errorCpf = document.getElementById("form-cpf-error");
            errorCpf.innerHTML = "";
            var errorEmail = document.querySelector(".form-email-error")
            if(errorEmail.textContent == "Endereço de email inválido ou já cadastrado."){
                errorEmail.style.display = "block";
                registerButton.disabled = true;
            }
        })

        registerButton.addEventListener("click", function(){
            if(!checkButton.checked){
                alertaBtnCheck.style.display = "block";
            }else{
                alertaBtnCheck.style.display = "none";
            }
        })

        function emailsEqual(){
            var errorEqualsEmails = document.getElementById("form-equals-emails");
            if(((emailConfirmInput.value.length > 0) && (emailInput.value.length > 0)) && (emailInput.value !== emailConfirmInput.value || emailConfirmInput.value !== emailInput.value)){
                errorEqualsEmails.style.display = "block";
                errorEqualsEmails.textContent = "Endereços diferentes";
                registerButton.disabled = true;
            } else if(((emailConfirmInput.value.length > 0) && (emailInput.value.length > 0)) && (emailInput.value === emailConfirmInput.value || emailConfirmInput.value === emailInput.value)){
                errorEqualsEmails.style.display = "none";
            } else{
                errorEqualsEmails.style.display = "none";
                disabledButton()
            }
        }

        function passwordsEqual(){
            var errorPasswordConfirm = document.getElementById("form-password-confirm-error");
            if(((passwordInput.value.length > 0)&&(passwordConfirmInput.value.length > 0)) && (passwordInput.value !== passwordConfirmInput.value)){
                errorPasswordConfirm.style.display = "block";
                let aviso = "Senhas estão diferentes";
                errorPasswordConfirm.textContent = aviso;
                registerButton.disabled = true;
            } else{
                errorPasswordConfirm.style.display = "none";
                disabledButton()
            }
        }

        function disabledButton(){
            if((nameInput.value !== '')&&(nameLastInput.value !== '')&&(cpfInput.value !== '')&&(celularInput.value !== '')&&(emailInput.value !== '')&&(emailConfirmInput.value !== '')&&(passwordInput.value !== '')&&(passwordConfirmInput.value !== '')){
                $('.registerButton').removeAttr('disabled');
            }
        }

        
};